.icon {
  font-size: 20px;
  color: #2f2a95;
  padding-right: 15px;
}
.titleLogin {
  color: #777777;
  font-size: 17px;
  font-family: "Ubuntu", sans-serif;
  margin-bottom: 20px;
  margin-top: 0;
}
::placeholder {
  color: #777777 !important;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
}

.inputForm {
  height: 55px;
  border-radius: 8px;
  padding-left: 20px;
  font-family: "Ubuntu", sans-serif;
  background: white !important;
}
@media only screen and (max-width: 480px) {
  .inputForm {
    font-size: 12px;
  }
  ::placeholder {
    font-size: 12px;
  }
  .icon {
    padding: 0px;
  }
}