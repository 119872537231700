.detailRecharge {
  border: 1px solid #181a7f;
  border-radius: 18px;
  margin: 0 auto;
}
.detailTable {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #181a7f;
  color: #777777;
  font-size: 12px;
}
.detailTableBold {
  font-weight: 700;
}
.detailRecharge div:last-child {
  border-bottom: none !important;
}
.detailRecharge div:first-child {
  background: #edecf4;
  border-radius: 18px 18px 0 0;
  color: #2f2a95;
  font-weight: 700;
  font-size: 14px;
}
.detailRecharge p {
  margin-left: 20px;
  margin-right: 20px;
}
