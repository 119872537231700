.table {
  border: 1px solid #181a7f;
  border-radius: 18px;
  margin: 0 auto;
  width: 80%;
}
.row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #181a7f;
  color: #777777;
  font-size: 12px;
}
.table div:last-child {
  border-bottom: none !important;
}
.table div:first-child {
  background: #edecf4;
  border-radius: 18px 18px 0 0;
  color: #2f2a95;
  font-weight: 700;
  font-size: 14px;
}
.table .row p {
  margin-left: 20px;
  margin-right: 20px;
}

.rowBold {
  font-weight: 700;
}
@media only screen and (max-width: 480px) {
  .table {
    width: 90%;
  }
  .table .row p {
    margin-left: 16px;
    margin-right: 0;
    font-size: 10px;
  }
  .table .row p:last-child {
    margin-right: 16px;
  }
}