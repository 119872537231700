.fotterText {
  color: #7774bb;
  font-size: 11px;
  font-family: "Ubuntu", sans-serif;
  display: flex;
  align-items: center;
  text-align: start;
}

.input {
  height: 55px;
  border-radius: 8px;
  font-family: "Ubuntu", sans-serif;
  color: #2f2a95;
}
.arrow {
  text-align: start;
  margin-bottom: 30px;
  margin-left: -15px;
  color: #2f2a95;
  font-size: 16px;
  font-family: "Ubuntu", sans-serif;
  cursor: pointer;
}
