.loader {
  transform: scale(1);
  animation: pulse 2s infinite;
  width: 200px;
  align-self: center;
}

@keyframes pulse {
  0% {
    transform: scale(1.5);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.5);
  }
}
