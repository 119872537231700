.title {
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #2f2a95;
}
.money {
  font-size: 20px;
  color: #2f2a95;
  padding-left: 10px;
  padding-right: 15px;
}
.titleLogin {
  color: #777777;
  font-size: 17px;
  font-family: "Ubuntu", sans-serif;
}
.fotterText {
  color: #777777;
  font-size: 9px;
  font-family: "Ubuntu", sans-serif;
  display: flex;
  align-items: center;
  text-align: start;
  @media only screen and (min-width: 1200px) {
    font-size: 11px
  }
}
.check {
  display: flex;
  color: #777777;
  font-size: 10px !important;
  font-family: "Ubuntu", sans-serif;
  align-items: center;
  @media only screen and (min-width: 1200px) {
    font-size: 12px !important;
    align-items: center;
  }
}
.inputForm {
  height: 55px;
  border-radius: 8px;
  padding-left: 70px !important;
  font-family: "Ubuntu", sans-serif;
  background: white !important;
  background-image: url("../../../assets/money.svg") !important;
  background-repeat: no-repeat !important;
  background-position: left !important;
  background-position: 20px 17px !important;
}
.url {
  color: #18a0fb;
  margin-left: 5px;
}
.inputFormMobile {
  height: 55px;
  border-radius: 8px;
  padding-left: 70px !important;
  font-family: "Ubuntu", sans-serif;
  background: #2F2A95 !important;
  background-image: url("../../../assets/money-mobile.svg") !important;
  background-repeat: no-repeat !important;
  background-position: left !important;
  background-position: 20px 17px !important;
  color: white !important;
}
.backButton {
  font-family: "Ubuntu", sans-serif;
  height: 40px;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  background: #2f2a95;
  color: white;
  font-size: 12px;
  font-weight: 700;
  border-radius: 12px;
  border: none;
  box-shadow: 2px 4px 8px 0px rgba(176, 195, 210, 0.7278) !important;
}
.continueButton {
  font-family: "Ubuntu", sans-serif;
  height: 40px;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  background: #2f2a95;
  color: white;
  font-size: 12px;
  font-weight: 700;
  border-radius: 12px;
  border: none;
  box-shadow: 2px 4px 8px 0px rgba(176, 195, 210, 0.7278) !important;
}