.successRecharge {
  border: 1px solid #181A7F;
  border-radius: 18px;
  width: 359px;
  margin: 0 auto;
}
.detailTable {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #181A7F;
  color: #777777;
  font-size: 12px;
}
.successRecharge div:last-child{
  border-bottom: none !important;
}
.successRecharge div:first-child{
  border-radius: 18px 18px 0 0;
  padding: 10px 0;
  color: #2F2A95;
  font-weight: 700;
  font-size: 14px;
}
.detailTable p {
  margin-left: 20px;
  margin-right: 20px;
}
.titleSuccess {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #2F2A95;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .successRecharge {
    width: auto;
  }
}
@media only screen and (max-width: 480px) {
  .detailTable p {
    margin-right: 10px;
    font-size: 10px;
    margin-left: 10px;
  }
  .successRecharge {
    width: 90%;
  }
}