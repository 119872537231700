.button {
  font-family: "Ubuntu", sans-serif;
  height: 54px;
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  background: #2f2a95;
  color: white;
  font-size: 15px;
  font-weight: 700;
  border-radius: 12px;
  border: none;
  box-shadow: -11px -11px 20px rgba(255, 255, 255, 0.272044),
    -8px -40px 22px rgba(246, 251, 255, 0.384288),
    -8px 0px 8px rgba(244, 248, 251, 0.50254),
    2px 4px 8px rgba(176, 195, 210, 0.727846);
}

.button:disabled {
  box-shadow: 2px 4px 8px 0px rgba(176, 195, 210, 0.7278) !important;
  color: #a7a5e1;
  background: white;
}

.labelForm {
  color: #2f2a95 !important;
  font-size: 13px !important;
  font-family: "Ubuntu", sans-serif !important;
  font-weight: 500 !important;
  display: flex;
  margin-bottom: 14px;
}

.line {
  height: 40px;
  margin-right: 10px;
  margin-left: 15px;
  width: 1px;
  background: #2F2A95;
}

.banks {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 9px;
  height: 64px;
  width: 100%;
  align-items: center;
  font-family: "Ubuntu", sans-serif;
}
.banks span {
  color: #2f2a95;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
}
.shadowButton {
  box-shadow: -11px -11px 20px rgba(255, 255, 255, 0.272044),
    -8px -40px 22px rgba(246, 251, 255, 0.384288),
    -8px 0px 8px rgba(244, 248, 251, 0.50254),
    2px 4px 8px rgba(176, 195, 210, 0.727846);
  border: 1px solid #d9d9d9 !important;
}
.container {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 0 40px;
}

.logos {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.logos img {
  height: auto;
}

.containerTrasaction {
  white-space: pre-line;
  text-align: start;
}
.containerTrasaction span {
  font-size: 12px;
}
.logoMaximo {
  position: relative;
  left: -20px;
}
.typeRecharge {
  display: flex;
  align-items: center;
}
.typeRechargeHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 110px;
}
@media only screen and (max-width: 480px) {
  .container {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;
    padding: 30px 20px;
  }
  .containerTrasaction{
    width: 326px;
  }
  .logos img {
    height: 16px;
    width: 88px;
  }
  .logos {
    width: 100%;
    margin-top: 10px;
  }
  .logoMaximo {
    left: 0px;
  }
  .containerTrasaction span {
    font-size: 14px;
  }
  .line {
    display: none;
  }
  .typeRecharge {
    flex-direction: column;
  }
  .typeRechargeHeader {
    width: 100%;
  }
  .banks {
    align-items: start;
  }
}
@media only screen and (min-width: 481px) {
  .logos img {
    height: 20px;
  }
  .logoMaximo {
    position: relative;
    left: 20px;
  }
}