@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

html,
body,
#root {
  height: 100%;
  margin: 0;
  font-family: Ubuntu, sans-serif;
  overflow: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.textNotification {
  color: #2f2a95;
  font-weight: 400;
  font-family: "Ubuntu", sans-serif;
}
.ant-checkbox-inner {
  box-shadow: inset -6px -3px 5px rgba(255, 255, 255, 0.750601),
    inset 3px 6px 6px #e0e0e7, inset 4px 2px 6px #b1c5d5,
    inset 0px 2px 4px #c8d4e2 !important;
  background: #f0f3f7;
  height: 25px !important;
  width: 25px !important;
}
.ant-select-selector {
  height: 55px !important;
  display: flex;
  align-items: center;
  box-shadow: 2px 4px 8px 0px rgba(176, 195, 210, 0.7278) !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px;
  text-align: start;
  padding-left: 20px;
  color: #2f2a95 !important;
  font-size: 14px !important;
  border-color: transparent !important;
}
.ant-select-arrow {
  transform: rotateZ(266deg) !important;
  color: #2f2a95 !important;
}
.ant-select-selection-placeholder {
  color: #2f2a95 !important;
  font-family: "Ubuntu", sans-serif;
}
.ant-input {
  padding-left: 20px !important;
}
.ant-select-item-option {
  color: #2f2a95 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.ant-select-item-option-active {
  background: #cacaef !important;
  color: #2f2a95 !important;
  font-family: "Ubuntu", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  box-shadow: -11px -11px 20px 0px rgba(255, 255, 255, 0.272) !important;
}

.ant-btn-default:hover {
  color: #a7a5e1 !important;
}
