.successRecharge {
  border: 1px solid #181a7f;
  border-radius: 18px;
  margin: 0 auto;
}
.detailTable {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #181a7f;
  color: #777777;
  font-size: 12px;
}
.successRecharge div:last-child {
  border-bottom: none !important;
}
.successRecharge div:first-child {
  border-radius: 18px 18px 0 0;
  padding: 10px 0;
  color: #2f2a95;
  font-weight: 700;
  font-size: 14px;
}
.successRecharge p {
  margin-left: 20px;
  margin-right: 20px;
}
.titleError {
  display: flex;
  align-items: center;
  text-align: start;
  color: #2f2a95;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 25px;
  margin-left: 30px;
}
.titleError p {
  margin-left: 10px;
}

.textError {
  width: 70%;
}
@media (max-width: 768px) {
  .textError {
    width: 80%;
    margin-top: 0;
  }
  .titleError {
    margin-left: 0;
    justify-content: center;
  }
}
