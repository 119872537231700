.icon {
  font-size: 20px;
  color: #2f2a95;
  padding-left: 10px;
  padding-right: 15px;
}
.titleLogin {
  color: #777777;
  font-size: 17px;
  font-family: "Ubuntu", sans-serif;
}
::placeholder {
  color: #777777 !important;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
}
.inputFormAmount {
  height: 55px;
  border-radius: 8px;
  padding-left: 70px !important;
  font-family: "Ubuntu", sans-serif;
  background: white !important;
  background-image: url("../../../assets/money.svg") !important;
  background-repeat: no-repeat !important;
  background-position: left !important;
  background-position: 20px 17px !important;
}
.inputFormCard {
  height: 55px;
  border-radius: 8px;
  padding-left: 70px !important;
  color: #1f2430 !important;
  font-family: "Ubuntu", sans-serif;
  background: white !important;
  background-image: url("../../../assets/card-icon.svg") !important;
  background-repeat: no-repeat !important;
  background-position: left !important;
  background-position: 20px 17px !important;
}
